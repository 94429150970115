import axios from '@axios'

export default {
  namespaced: true,
  state: {
    respData: null,
    respDataBank: null,
    respDataPaymentBank: null,
  },
  getters: {
  },
  mutations: {
    GET_CONFIG_APPLICATION(state, payload) {
      state.respData = payload
    },
    SWITCH_CHANGE(state, item) {
      const nameChild = item.name.split('.')

      if (nameChild.length === 3) {
        state.respData[nameChild[0]][nameChild[1]][nameChild[2]] = item.status
      } else if (nameChild.length === 2) {
        state.respData[nameChild[0]][nameChild[1]] = item.status
      } else {
        state.respData[item.name] = item.status
      }
    },
    INPUT_CHANGE(state, item) {
      const nameChild = item.name.split('.')

      if (nameChild.length === 2) {
        state.respData[nameChild[0]][nameChild[1]] = item.value
      } else if (nameChild.length === 3) {
        state.respData[nameChild[0]][nameChild[1]][nameChild[2]] = item.value
      } else {
        state.respData[item.name] = item.value
      }
    },
    UPDATE(state, payload) {
      state.respData = payload
    },
  },
  actions: {
    get({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/web/root/applicationSettings/get?applicationId=${item._id}`)
          .then(response => {
            commit('GET_CONFIG_APPLICATION', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    inputChange({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/web/root/applicationSettings/inputChange?applicationId=${item._id}`, item)
          .then(response => {
            commit('UPDATE', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    switchChange({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/web/root/applicationSettings/switchChange?applicationId=${item._id}`, item)
          .then(response => {
            commit('SWITCH_CHANGE', item)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    checkAppbundle({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/web/root/applicationSettings/checkAppbundle?applicationId=${item._id}`, item)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    uploadFile({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/web/root/applicationSettings/uploadFile?applicationId=${item._id}`, item)
          .then(response => {
            // commit('SWITCH_CHANGE', item)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    uploadJson({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/web/root/applicationSettings/uploadJson?applicationId=${item._id}`, item)
          .then(response => {
            // commit('SWITCH_CHANGE', item)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    getLineAudienceGroupList({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/web/root/applicationSettings/getLineAudienceGroupList?applicationId=${item._id}`, item)
          .then(response => {
            // commit('SWITCH_CHANGE', item)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    sendLineMessagingAPI({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/web/root/applicationSettings/sendLineMessagingAPI?applicationId=${item._id}`, item)
          .then(response => {
            // commit('SWITCH_CHANGE', item)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getBotLineMessagingAPI({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/web/root/applicationSettings/getBotLineMessagingAPI?applicationId=${item._id}`, item)
          .then(response => {
            // commit('SWITCH_CHANGE', item)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
